<i18n>
ru:
  incorrect: Неправильная настройка продукта!
  notFound: "Нет активных продуктов в этом разделе"
  notFoundFilter: Не найдены продукты по заданным фильтрам
  noConstructor: Конструктор не найден!
ua:
  incorrect: Неправильне налаштування продукту!
  notFound: "Немає активних продуктів у цьому розділі"
  notFoundFilter: Не знайдено продуктів за вказаними фільтрами
  noConstructor: Конструктор не знайдений!
us:
  incorrect: Incorrect product setting!
  notFound: "No active products in this section"
  notFoundFilter: No products found matching specified filters
  noConstructor: The constructor was not found!
</i18n>

<template>
  <div
    class="v-d-flex v-flex-row v-flex-100 v-menu-list-wrapper"
    data-test-id="menu-list-wrapper"
  >
    <div
      v-if="menuStore.CurrentGroup"
      class="v-d-flex v-flex-row v-flex-100 v-mb-sm"
    >
      <div
        class="v-menu-title v-title v-mb-sm"
        v-html="
          sanitize(
            stringIsNullOrWhitespace(menuStore.CurrentGroup.SeoData?.PageTitleOverride)
              ? menuStore.CurrentGroup.Name
              : menuStore.CurrentGroup.SeoData.PageTitleOverride
          )
        "
      />
      <div
        class="v-mb-sm"
        v-html="menuStore.CurrentGroup.MenuSpecialText"
      />
      <menu-filters-page />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.GiftsRangedShowInMenuAsInCart &&
        appConfig.VueSettingsPreRun.GiftsRangedShowInMenu
      "
      class="v-mb-sm v-flex-100"
    >
      <cart-old-gifts-ranged />
    </div>
    <menu-list-fanuidhol
      :group-id="groupId"
      :subgroup-id="subgroupId"
      v-if="appConfig.VueSettingsPreRun.MenuListLayout === 'Fanuidhol'"
    />
    <menu-list-orodruin
      v-else-if="appConfig.VueSettingsPreRun.MenuListLayout === 'Orodruin'"
    />
  </div>
</template>

<script setup lang="ts">
import type { MenuList } from '~types/props'

import { useCommon } from '@arora/common'

defineProps<MenuList>()

const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-menu-title {
  flex: 0 0 50%;
  max-width: 50%;
  font-weight: 600;
  font-size: variables.$TextSizeH1;
  color: variables.$BodyTextColor;

  @include mixins.sm {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
